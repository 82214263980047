function EnableCookie(){
    $.post(DOMAIN+"session/", {setsession: "EnableCookie"});
    $("#CookieWarning").fadeOut(function (){
        $(this).remove();
    });
}

$(".sem:not(a)").each(function () {
    var em = $(this).attr("data-e0") + "@" + $(this).attr("data-e1") + "." + $(this).attr("data-e2");
    $(this).html('<a href="mailto:' + em + '">' + em + '</a>');
});
$("a.sem").each(function () {
    var em = $(this).attr("data-e0") + "@" + $(this).attr("data-e1") + "." + $(this).attr("data-e2");
    $(this).attr('href', 'mailto:' + em);
});

/**
 * Created by grammi on 2017.04.13..
 */
$(document).ready(function(){

    $('#header-reviews').show();

    $('#header-reviews').slick({
        arrows: true,
        dots: false,
        autoplay: true
    });

    $('.other-products-slider').slick({
        arrows: true,
        dots: true,
        prevArrow: "<img src='images/nordagro/slide-prev.png' id='slick-prev'/>",
        nextArrow: "<img src='images/nordagro/slide-next.png' id='slick-next'/>",
        slidesToShow: 5,
        slidesToScroll:5,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
});
$(function(){
    $(window).bind("load resize", function()
    {
        var sfh =  Math.floor ( $('#sticky-footer').outerHeight() );
        $('#page-wrap').css("padding-bottom", sfh+'px');
        $('#page-wrap').css("margin-bottom", ((-1) * sfh)+'px');
    });
});
$(window).bind("load resize", function()
{
    $("#left-side").css("height","auto");

    var ww = $(window).width();

    if (ww > 576) {
        var nh =  $(window).outerHeight()-$('header').outerHeight();
        var lsh =  $("#left-side").height();
        if (nh > lsh) {
        $("#left-side").height(nh);
        }
    }
});