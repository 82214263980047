/* GÖRGETÉS ELEMHEZ */
function scrollToId(i,d,s,o)
{
    if(d==undefined)
    {
        var d=100
    }if(s==undefined)
{
    var s=1000
}if(o==undefined)
{
    var o=-10
}try
{
    $("html, body").delay(d).animate({scrollTop:$("#"+i).offset().top+o},s)
}catch(err)
{
}
};

/* HTML CSERE */
function changeHtml(i,h,fi,fo)
{
    if(fi==undefined)
    {
        var fi="fast"
    }if(fo==undefined)
{
    var fo="fast"
}try
{
    $("#"+i).fadeOut(fo,function(){$("#"+i).html(h).fadeIn(fi)})
}catch(err)
{
}
};

/* INPUT SZŰRÉS CSAK BETŰ, SZÁM, STB */
var telNumber=/[1234567890\/\(\)\+\-\s]/g;var digitsOnly=/[1234567890]/g;var integerOnly=/[0-9\.]/g;var floatOnly=/[0-9\.\,]/g;var alphaOnly=/[A-Za-z]/g;var usernameOnly=/[0-9A-Za-z\._-]/g;function restrictInput(h,i,j,g)
{
    if(!i)
    {
        var i=window.event
    }if(i.keyCode)
{
    code=i.keyCode
}else
{
    if(i.which)
    {
        code=i.which
    }
}var e=String.fromCharCode(code);if(code==27)
{
    this.blur();return false
}if(!i.ctrlKey&&code!=9&&code!=8&&code!=36&&code!=37&&code!=38&&(code!=39||(code==39&&e=="'"))&&code!=40)
{
    if(e.match(j))
    {
        if(g=="checkdot")
        {
            if(((code!=46&&code!=44)||($(h).val().indexOf(".")!=-1||$(h).val().indexOf(",")!=-1))&&(code<48||code>57))
            {
                return !isNaN(h.value.toString()+e)
            }else
            {
                return true
            }
        }else
        {
            return true
        }
    }else
    {
        return false
    }
}
}function replaceComa(a)
{
    var b=$(a).val();b=b.replace(/,/g,".");$(a).val(b)
};function replaceDOT(b)
{
    var a=$(b).val();$(b).val(a.replace(",","."))
};

$( document ).ready(function() {
    //$('#navbar-submenu-1').show();
    $('#navbar-container-left li a').click(function( event ){
        event.preventDefault();
        $('#navbar-container-left li a').removeClass('active');
        $(this).addClass('active');
        $('.navbar-submenu').hide();
        var subid='#'+$(this).data('sub');
        var img='images/'+$(this).data('img');
        $(subid).show();
        $(".tab-pane-second-block img").attr("src",img);
    });

    $('#top_header .dropdown').hover(function() {
        $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(500);
    }, function() {
        $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
    });
});

function openNav() {
    document.getElementById("hamburgerNav").style.width = "100%";
    document.getElementById("hamburgerNav").style.height = "100%";
}

function closeNav() {
    document.getElementById("hamburgerNav").style.width = "0%";
    document.getElementById("hamburgerNav").style.height = "0%";
}

/* Kereso */
/*$( "#navbar-submenu-2,#navbar-submenu-4" ).click(function() {
    $('#tipus-tab').addClass('disabled');
    $('#tipus,#tipus-tab').removeClass('active');
    $('#meret,#meret-tab').addClass('active');

    if(this.id=='navbar-submenu-4'){
        $('#meret-select').hide();
    }
    else{
        $('#meret-select').show();
    }
});

$( "#navbar-submenu-1,#navbar-submenu-3,#navbar-submenu-5" ).click(function() {
    $('#tipus-tab').removeClass('disabled');
    $('#meret-select').show();
});*/





